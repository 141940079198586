import { useFormatter, useNow } from 'next-intl';
import { dayjs } from '@zealy/utils';
// Duplicated hook from @zealy/webapp. If tried to share it in @zealy/utils but I had a lot of typescript error on the next-intl package
export const useRelativeTimeFromNow = (date, updateInterval) => {
    const now = useNow(updateInterval
        ? {
            updateInterval,
        }
        : undefined);
    const format = useFormatter();
    if (dayjs(now).diff(date, 'seconds') < 60) {
        return 'few seconds ago';
    }
    return format.relativeTime(date);
};
