'use client';

import React from 'react';
import { useTranslations } from 'next-intl';
import NextLink from 'next/link';

import { Button, cn, Notification } from '@zealy/design-system';
import { InboxLine } from '@zealy/icons';

import type { InboxProps } from './Inbox.types';

export const Inbox = ({
  className,
  notifications,
  onMarkAllAsRead,
  onMarkReadChange,
  onScrollEnd,
  loading,
  markAllAsReadPending,
  children,
  ...props
}: InboxProps) => {
  const t = useTranslations('inbox');

  const hasUnreadNotifications = notifications.some(({ read }) => !read);

  const handleScroll = (e: any) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 100;

    if (bottom && onScrollEnd) {
      onScrollEnd();
    }
  };

  return (
    <div
      className={cn(
        'flex flex-col w-full rounded-inbox-popover bg-inbox-popover overflow-hidden min-h-[400px]',
        className,
      )}
      {...props}
    >
      <div className="flex justify-between items-center p-300 flex-shrink-0 border-b">
        <p className="inbox-popover text-primary">{t('title')}</p>
        <Button
          variant="muted"
          size="sm"
          isDisabled={!hasUnreadNotifications || markAllAsReadPending}
          loading={markAllAsReadPending}
          onClick={() => onMarkAllAsRead()}
        >
          {t('mark-all-as', {
            status: t('read'),
          })}
        </Button>
      </div>
      <div className="flex flex-col overflow-y-auto flex-1" onScroll={handleScroll}>
        {children}
        {notifications.map(notification => (
          <Notification
            {...notification}
            key={notification.id}
            onReadChange={read => onMarkReadChange([notification.id], read)}
            className={notification.href ? '' : 'hover:bg-transparent'}
            linkAs={NextLink}
          />
        ))}
        {notifications.length === 0 && !loading && (
          <div className="mx-auto items-center justify-center flex flex-col gap-150 py-400 flex-1 h-full">
            <InboxLine className="w-600 h-600 icon-tertiary" />
            <p className="body-component-lg text-tertiary">{t('empty')}</p>
          </div>
        )}

        {loading && (
          <>
            <Notification.Skeleton key={0} />
            <Notification.Skeleton key={1} />
            <Notification.Skeleton key={2} />
          </>
        )}
      </div>
    </div>
  );
};
