'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useRef } from 'react';
import { useTranslations } from 'next-intl';
import { CheckCircle2Filled, CrossCircleFilled, InboxFilledLine, InboxLine, LoaderLine, } from '@zealy/icons';
import { useDidMount } from '../../hooks/useDidMount';
import { useRelativeTimeFromNow } from '../../hooks/useRelativeTimeFromNow';
import { cn } from '../../utils/cn';
import { Avatar } from '../Avatar';
import { Checkbox } from '../Checkbox';
import { IconButton } from '../IconButton';
import { TooltipProvider } from '../Tooltip';
import { styles } from './Notification.styles';
import { NotificationSkeleton } from './NotificationSkeleton';
export const StatusIcons = {
    success: CheckCircle2Filled,
    fail: CrossCircleFilled,
    pending: LoaderLine,
};
export const StatusIcon = ({ status, className }) => {
    return status ? StatusIcons[status]({ className: cn(styles.icon({ status }), className) }) : null;
};
export const NotificationIcon = ({ icon, status }) => {
    return icon ? (React.cloneElement(icon, { className: cn(styles.icon({ status }), icon.props.className) })) : (_jsx(StatusIcon, { status: status }));
};
export const Actions = ({ read, onReadChange, }) => {
    const t = useTranslations('inbox');
    return (_jsx(TooltipProvider, { children: _jsx(IconButton, { icon: read ? _jsx(InboxFilledLine, {}) : _jsx(InboxLine, {}), tooltip: t('mark-as', {
                status: t(!read ? 'read' : 'unread'),
            }), onClick: e => {
                e.preventDefault();
                e.stopPropagation();
                onReadChange?.(!read);
            }, variant: "muted", size: "sm", className: "pointer-fine:opacity-0 group-hover:opacity-100 transition-opacity" }) }));
};
export const Notification = ({ className, read, createdBy, createdAt, action, href, resource, description, onReadChange, onSelectedChange, status, icon, selected, open, onClick, children, linkAs: LinkComponent = 'a', ...props }) => {
    const mainAnchorRef = useRef(null);
    const t = useTranslations('reviews');
    const didMount = useDidMount();
    const relativeTime = useRelativeTimeFromNow(createdAt, 1000);
    const handleClick = (e) => {
        onClick?.(e);
        onReadChange?.(true);
        if (mainAnchorRef.current) {
            mainAnchorRef.current.click();
        }
    };
    return (_jsxs("div", { className: cn(styles.root({ read }), className), "data-selected": selected ? true : undefined, "data-open": open ? true : undefined, onClick: handleClick, "data-testid": "notification", ...props, children: [_jsxs("div", { className: "flex items-center gap-inbox", onClick: e => {
                    e.stopPropagation();
                }, children: [typeof read === 'boolean' && (_jsx("svg", { width: "8", height: "8", viewBox: "0 0 8 8", fill: "none", xmlns: "http://www.w3.org/2000/svg", className: read ? 'opacity-0' : 'icon-inbox-badge', children: _jsx("circle", { cx: "4", cy: "4", r: "4", fill: "currentColor" }) })), typeof onSelectedChange !== 'undefined' && (_jsx(Checkbox, { checked: selected, onCheckedChange: onSelectedChange, "aria-label": t('select-toggle') })), createdBy && (_jsx(LinkComponent, { href: createdBy.href, className: "flex", children: _jsx(Avatar, { name: createdBy.name, src: createdBy.avatarUrl, size: "xxs" }) }))] }), _jsxs("div", { className: "flex flex-col gap-inbox flex-1 select-none", children: [_jsxs("span", { className: "inbox-description text-inbox-title items-center", children: [createdBy && (_jsx(LinkComponent, { href: createdBy.href, className: "inbox-title mr-50 hover:underline underline-offset-2", children: createdBy.name })), action, didMount && (_jsx("span", { className: "inbox-timestamp text-inbox-description ml-100", children: relativeTime }))] }), resource &&
                        (href ? (_jsx(LinkComponent, { ref: mainAnchorRef, href: href, className: "inbox-title text-inbox-title hover:underline underline-offset-2 ", children: resource })) : (_jsx("div", { className: "inbox-title text-inbox-title hover:underline underline-offset-2 ", children: resource }))), description && _jsx("p", { className: "inbox-description text-inbox-description", children: description })] }), _jsxs("div", { children: [status && _jsx("span", { className: "sr-only", children: t(`statuses.${status}`) }), _jsx(NotificationIcon, { status: status, icon: icon })] }), onReadChange && _jsx(Actions, { read: !!read, onReadChange: onReadChange })] }));
};
Notification.Skeleton = NotificationSkeleton;
