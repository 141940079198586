'use client';

import { useTranslations } from 'next-intl';
import { useParams, useRouter } from 'next/navigation';

import type { ListNotificationsOutput } from '@zealy/queries';
import { getSubdomain, truncateAddress } from '@zealy/utils';

import type { InboxProps } from '#features/Inbox';

export const useFormatNotification = () => {
  const router = useRouter();
  const t = useTranslations('inbox');
  const tRewardMethods = useTranslations('quest.methods.type');
  const { subdomain } = useParams<{ subdomain: string }>();

  return (
    notification: ListNotificationsOutput['items'][number],
  ): InboxProps['notifications'][number] => {
    const baseFormatting = {
      id: notification.id,
      createdAt: new Date(notification.createdAt),
      read: notification.read,
      createdBy: {
        avatarUrl: notification.data.createdBy.avatar,
        name: notification.data.createdBy.name ?? '',
        href: `/cw/${subdomain}/users/${notification.data.createdBy.id}`,
      },
    } as const;

    switch (notification.type) {
      case 'review':
        return {
          ...baseFormatting,
          action: `${t(notification.type, {
            status: notification.data.mark ?? notification.data.status,
          })}${
            notification.data.bonusXP
              ? t('bonus', {
                  xp: notification.data.bonusXP,
                })
              : ''
          }`,
          description: notification.data.comment,
          resource: notification.data.resource.name,
          onClick: notification.data.claimId
            ? () => {
                router.push(`/cw/${getSubdomain()}/inbox/${notification.data.claimId}`);
              }
            : undefined,
        };
      case 'manual-xp':
        return {
          ...baseFormatting,
          action: t(
            `${notification.type}.${notification.data.value > 0 ? 'positive' : 'negative'}`,
            {
              xp: notification.data.value,
            },
          ),
          description: notification.data.comment,
        };
      case 'invite-accepted':
        return {
          ...baseFormatting,
          action: t(notification.type),
        };

      case 'partnership-accepted':
        return {
          ...baseFormatting,
          action: t(notification.type),
        };

      case 'partnership-requested':
        return {
          ...baseFormatting,
          action: t(`${notification.type}.action`),
          resource: t(`${notification.type}.resource`, {
            communityName: notification.data.communityName,
          }),
          href: `/cw/${getSubdomain()}/partnerships`,
          description: notification.data.message,
        };

      case 'top-reward-distributed':
      case 'vote-reward-distributed':
      case 'raffle-reward-distributed': {
        return {
          id: notification.id,
          createdAt: new Date(notification.createdAt),
          read: notification.read,
          createdBy: {
            avatarUrl: notification.data.createdBy.avatar,
            name: notification.data.createdBy.name ?? '',
            href: `/cw/${notification.data.createdBy.subdomain}/questboard/${notification.data.resource.quest.categoryId}/${notification.data.resource.quest.id}`,
          },
          action: t('reward-distributed.action', {
            type: t(`reward-distributed.reward-method.${notification.type}`),
          }),
          resource: notification.data.resource.quest.name,
          description: t(`reward-distributed.type.${notification.data.type}`, {
            ...notification.data,
            ...(notification.data.type === 'token'
              ? {
                  value: notification.data.value,
                  networkName: t(`network.${notification.data.network}`),
                  symbol: notification.data.symbol,
                  shortedAddress: truncateAddress(notification.data.address),
                }
              : {}),
          } as any),
        };
      }

      case 'admin-quest-reward-distributed': {
        return {
          id: notification.id,
          createdAt: new Date(notification.createdAt),
          read: notification.read,
          createdBy: {
            avatarUrl: notification.data.createdBy.avatar,
            name: notification.data.createdBy.name ?? '',
            href: `/cw/${notification.data.createdBy.subdomain}/questboard/admin/${notification.data.resource.quest.id}`,
          },
          action: t('admin-quest-reward-distributed.title', {
            rewardMethod: tRewardMethods(`${notification.data.resource.rewardMethod.type}.name`),
          }),
          resource: notification.data.resource.quest.name,
          description: t(
            `admin-quest-reward-distributed.type.${notification.data.type}`,
            notification.data as any,
          ),
          href: `/cw/${notification.data.createdBy.subdomain}/questboard/admin/${notification.data.resource.quest.id}?tab=results&sub-tab=rewards`,
        };
      }

      case 'token-reward-sent': {
        return {
          id: notification.id,
          createdAt: new Date(notification.createdAt),
          read: notification.read,
          createdBy: {
            avatarUrl: notification.data.createdBy.avatar,
            name: notification.data.createdBy.name ?? '',
            href: `/cw/${notification.data.createdBy.subdomain}/questboard/${notification.data.resource.quest.categoryId}/${notification.data.resource.quest.id}`,
          },
          action: t('token-reward-sent.action'),
          resource: notification.data.resource.quest.name,
          description: t(`token-reward-sent.description`, {
            value: notification.data.value,
            networkName: t(`network.${notification.data.network}`),
            symbol: notification.data.symbol,
            shortedAddress: truncateAddress(notification.data.address),
          }),
        };
      }
    }
  };
};
