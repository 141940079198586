'use client';

import React from 'react';
import { useTranslations } from 'next-intl';

import type { FailedDiscordRolesDelivery } from '@zealy/queries';
import { Alert, Button } from '@zealy/design-system';
import { FetchError, resendFailedDiscordRoles } from '@zealy/queries';
import { discordKeys } from '@zealy/queries/src/discord/discord.keys';

import { queryClient } from '#app/QueryProvider';
import { toast } from '#components/Toaster';
import { revalidatePaths } from '#utils/serverAction';

export const FailedDiscordRolesAlert = ({
  failedDiscordRolesDelivery,
  subdomain,
}: {
  failedDiscordRolesDelivery?: FailedDiscordRolesDelivery['data'];
  subdomain: string;
}) => {
  const t = useTranslations('settings.discord.failed-roles-delivery');

  const handleResendRoles = async () => {
    try {
      await resendFailedDiscordRoles(subdomain);
      revalidatePaths([`/cw/${subdomain}/settings/integrations/discord`]);
      queryClient.invalidateQueries({ queryKey: discordKeys.failedRoles(subdomain) });
      toast.success(t('send-success'));
    } catch (error) {
      if (error instanceof FetchError) toast.error(error.response?._data?.message ?? error.message);
    }
  };

  return (
    <>
      {!!failedDiscordRolesDelivery?.missingPermissionCount && (
        <Alert
          title={t('title', {
            count: failedDiscordRolesDelivery?.missingPermissionCount,
          })}
          description={t('missing-permissions')}
          variant="warning"
          actions={
            <Button variant="muted" onClick={handleResendRoles}>
              {t('resend')}
            </Button>
          }
        />
      )}
      {!!failedDiscordRolesDelivery?.memberNotFoundCount && (
        <Alert
          title={t('title', {
            count: failedDiscordRolesDelivery?.memberNotFoundCount,
          })}
          description={t('members-not-found')}
          variant="warning"
        />
      )}
    </>
  );
};
