'use client';

import React from 'react';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import { useParams } from 'next/navigation';

import type { CommunityMember } from '@zealy/queries';
import {
  useAuthenticatedUser,
  useFailedAutomations,
  useInbox,
  useUpdateNotificationStatus,
} from '@zealy/queries';
import { roleIsAtLeast } from '@zealy/utils';

import { FailedDiscordRolesAlert } from '#app/cw/[subdomain]/settings/(community)/integrations/discord/_components/FailedDiscordRolesAlert';
import { toast } from '#components/Toaster';
import { Inbox } from '#features/Inbox';

import { useFormatNotification } from './InboxContent.hooks';

export const InboxContent = () => {
  const { itemId, subdomain } = useParams<{ itemId?: string; subdomain: string }>();
  const inbox = useInbox();
  const t = useTranslations('common');

  const { data: user } = useAuthenticatedUser<CommunityMember>(subdomain);

  const discordAlerts = useFailedAutomations(subdomain, {
    enabled: roleIsAtLeast(user?.role, 'admin'),
  });

  const formatNotification = useFormatNotification();

  const updateNotifications = useUpdateNotificationStatus();

  const markAsRead = (ids?: string[], read = true) => {
    if (updateNotifications.isPending) return;
    updateNotifications.mutate(
      { read, ids },
      {
        onError: () => toast.error(t('toast-error')),
      },
    );
  };

  const handleScrollEnd = () => {
    if (!inbox.isFetching && inbox.hasNextPage) inbox.fetchNextPage();
  };

  const notifications =
    inbox.data?.pages.flatMap(page => page.items ?? []).map(formatNotification) ?? [];

  return (
    <div
      className={clsx('bg-secondary rounded-md h-full w-full flex-1 max-w-screen-md', {
        'hidden lg:flex': itemId,
      })}
    >
      <Inbox
        notifications={notifications}
        onMarkAllAsRead={markAsRead}
        onMarkReadChange={markAsRead}
        onScrollEnd={handleScrollEnd}
        className="bg-transparent h-full min-h-0"
        loading={inbox.isLoading || inbox.isFetching}
        markAllAsReadPending={updateNotifications.isPending}
      >
        <div className="p-100">
          <FailedDiscordRolesAlert
            subdomain={subdomain}
            failedDiscordRolesDelivery={discordAlerts.data}
          />
        </div>
      </Inbox>
    </div>
  );
};
