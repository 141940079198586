import { cva } from 'class-variance-authority';
const root = cva([
    'outline-none',
    'flex items-start py-inbox-y px-inbox-x gap-inbox',
    'border-b last-of-type:border-b-transparent',
    'group',
    'cursor-pointer',
    'transition-color',
    'data-[open]:bg-inbox-selected',
    'data-[selected]:bg-inbox-selected',
    'border-l-2 border-l-transparent data-[open]:border-l-brand-secondary',
    'hover:bg-component-quaternary-hover active:bg-component-quaternary-press',
], {
    variants: {
        read: {
            true: 'bg-inbox-read',
            false: 'bg-inbox-unread',
        },
    },
    defaultVariants: {
        read: true,
    },
});
const icon = cva('w-icon-md h-icon-md', {
    variants: {
        status: {
            success: 'icon-success-primary',
            fail: 'icon-error-primary',
            default: 'icon-tertiary',
            pending: 'icon-tertiary',
        },
    },
});
export const styles = {
    root,
    icon,
};
